<template>
    <!-- <div class="page-wrapper">
        <div class="splash-newsletter">
            <div class="title-splash-wrap">
                <img src="@/assets/img/title-splash.png" class="title-splash" alt="Cigale" />
            </div>
            <div class="iframe">
                <iframe src=https://caimgs.s3-ca-central-1.amazonaws.com/pre-rendered/dec04eb9a85cbd5b774062920117f31763f654e65e87f7cff27bc31742ffdbad.html scrolling="yes" marginwidth="0" marginheight="0" frameborder="0" vspace="0" hspace="0"></iframe>
            </div>
        </div>
    </div> -->
    <div class="page-wrapper">
        <h1 class="seo">Cigale - Musique, bouffe, plage</h1>
        <section class="hero" v-if="contentIsLoaded">
            <h2 class="title left" data-inview="fadeIn" data-delay="400">
                <span v-html="content.hero.titreGauche"></span>
            </h2>
            <h2 class="title right" data-inview="fadeIn" data-delay="600">
                <span v-html="content.hero.titreDroite"></span>
            </h2>

            <a href="#intro" v-scroll-to:0
                ><img
                    src="@/assets/img/arrow-down-white.svg"
                    class="arrow"
                    alt="Arrow down"
                    data-inview="fadeIn"
                    data-delay="200"
            /></a>

            <div>
                <img
                    :src="content.hero.logo.url"
                    class="hero-logo"
                    :alt="content.hero.logo.titre"
                    data-inview="fadeInUp"
                    data-delay="200"
                />
            </div>

            <div><img src="@/assets/img/hero-wave.svg" class="wave" alt="Wave" /></div>
            <div class="gradient-1"></div>
            <div class="gradient-2"></div>

            <video
                class="video"
                :src="content.hero.video.url"
                playsinline
                autoplay
                muted
                loop
            ></video>
        </section>

        <section class="intro" id="intro" v-if="contentIsLoaded">
            <img
                src="@/assets/img/plus.svg"
                class="plus"
                alt="Plus"
                data-inview="scale"
                data-delay="400"
            />
            <div class="left">
                <h2 class="title" data-inview="fadeInUp" data-delay="200">
                    <span v-html="content.intro.titreGauche"></span>
                </h2>
            </div>
            <div class="right">
                <h2 class="title" data-inview="fadeInUp" data-delay="600">
                    <span v-html="content.intro.titreDroite"></span>
                </h2>
            </div>
        </section>

        <section class="video-wrap" v-if="contentIsLoaded">
            <a :href="content.intro.video" class="glightbox">
                <div class="video" data-inview="revealLeft" data-delay="200">
                    <img src="@/assets/img/play.svg" class="play" alt="Play" />
                    <div class="overlay"></div>
                    <img
                        :src="content.intro.image.url"
                        class="video-img"
                        :alt="content.intro.image.titre"
                    />
                </div>
            </a>
        </section>

        <section class="text-horizontal-scroll" v-if="contentIsLoaded">
            <div class="line line1 odd">
                <p class="title" v-for="(mot, i) in content.ligne1" :key="i + 'l1'" v-html="mot"></p>
            </div>
            <div class="line line2 even">
                <p class="title" v-for="(mot, i) in content.ligne2" :key="i + 'l2'" v-html="mot"></p>
            </div>
            <div class="line line3 odd">
                <p class="title" v-for="(mot, i) in content.ligne3" :key="i + 'l3'" v-html="mot"></p>
            </div>
        </section>

        <section class="headliners-list" v-if="contentIsLoaded">
            <h2 class="title align-center" data-inview="fadeInUp" data-delay="200">
                {{ content.titreSimple }}
            </h2>

            <a
                :href="item.jsonUrl"
                class="artiste-wrap"
                :class="{ 'big': content.artistesMisDeLavant.length <= 2, 'small': content.artistesMisDeLavant.length > 2}"
                data-inview="fadeInUp"
                data-delay="600"
                v-for="(item, i) in content.artistesMisDeLavant"
                :key="i + 'art'"
            >
                <div class="img">
                    <div class="overlay"></div>
                    <img :src="item.image.url" :alt="item.image.titre" />
                </div>
                <footer>
                    <div class="top">
                        <h2 class="title">{{ item.titre }}</h2>
                        <img src="@/assets/img/arrow-right-green.svg" alt="Spotify logo" />
                    </div>
                </footer>
            </a>

            <div data-inview="fadeInUp" data-delay="200" class="center-button" v-if="pageIsLoaded">
                <a :href="content.lienUrl[$route.meta.lang]" class="button-cta">
                    <p class="title">{{ content.lien.customText }}</p>
                </a>
            </div>
        </section>

        <section class="deal-wrap" v-if="pageIsLoaded && globals.mainNav.activerLaBilletterie">
            <div
                class="deal"
                :class="{ 'special': item.couleursOffres.value === 'jaune', 'full-width': globals.offres.data.length === 1, 'less-pad': globals.offres.data.length == 3 }"
                data-inview="fadeInUp"
                data-delay="200"
                v-for="(item, i) in globals.offres.data"
                :key="i + 'offr'"
            >
                <div class="top">
                    <div class="left">
                        <p class="title name">{{ item.page.title }}</p>
                        <p class="title price">{{ item.prix }}</p>
                    </div>
                    <div class="right">
                        <img :src="item.imageSimple.url" :alt="item.imageSimple.titre" />
                    </div>
                </div>
                <ul class="details">
                    <li v-for="(line, j) in item.liste" :key="j + 'list'">
                        <img src="@/assets/img/small-arrow-right-orange.svg" alt="Arrow right" />
                        <p class="text">{{ line.texte }}</p>
                    </li>
                </ul>
                <div class="bottom" v-if="!item.complet">
                    <a :href="item.lienUrl" target="_blank" class="button-cta">
                        <p class="title">{{ item.lien.customText }}</p>
                    </a>
                </div>
                <div class="bottom" v-else>
                    <p class="title name">{{ $t('COMPLET') }}</p>
                </div>
            </div>

            <div data-inview="fadeInUp" data-delay="200" class="center-button" v-if="globals.footer.lien && globals.footer.lien.customText"><a :href="globals.footer.lienAsset[$route.meta.lang] ? globals.footer.lienAsset[$route.meta.lang] : globals.footer.lienAsset" target="_blank" class="button-cta" :title="globals.footer.lien.customText"><p class="title">{{ globals.footer.lien.customText }}</p></a></div>
        </section>

        <section class="container home-special" v-if="contentIsLoaded">
            <div
                class="split-img-txt img-left"
                v-for="(item, i) in content.texteEtImages"
                :key="i + 'txm'"
            >
                <div class="img-wrap" data-inview="revealLeft" data-delay="200">
                    <div class="carousel carousel-init" ref="flickity">
                        <div class="carousel-cell" v-for="(img, i) in item.images" :key="i + 'im'">
                            <img :src="img.url" :alt="img.titre" />
                        </div>
                    </div>
                </div>
                <div class="content" data-inview="fadeInUp" data-delay="400">
                    <h3 class="title small"><span v-html="item.titre"></span></h3>
                    <p class="text">
                        <span v-html="item.texte"></span>
                    </p>
                    <a :href="item.boutonUrl[$route.meta.lang]" class="button-cta dark">
                        <p class="title">{{ item.bouton.customText }}</p>
                    </a>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import Flickity from 'flickity'

import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { gsap } from 'gsap'

import { mapState, mapGetters } from 'vuex'
import GLightbox from 'glightbox'

export default {
    name: 'Home',

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    setTimeout(() => {
                        [].slice.call(document.querySelectorAll('.carousel-init')).forEach(el => {
                            const nbImgs = [].slice.call(el.querySelectorAll('.carousel-cell'))
                            new Flickity(el, {
                                prevNextButtons: nbImgs.length >= 2,
                                pageDots: false,
                                contain: true,
                                autoPlay: true,
                                wrapAround: true,
                                imagesLoaded: true,
                            })
                        })

                        // Page loaded with Data
                        initInViewAnimations()

                        this.lightboxelement = GLightbox
                        GLightbox()

                        gsap.to('.line1', { x: '-150vw', duration: 80, ease: 'none', repeat: -1 })
                        gsap.to('.line2', { x: '150vw', duration: 80, ease: 'none', repeat: -1 })
                        gsap.to('.line3', { x: '-150vw', duration: 80, ease: 'none', repeat: -1 })
                    }, 155)
                }
            },
            immediate: true,
        },
    },

    data() {
        return {
            flk2: null,
        }
    },

    mounted() {},

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>
